<template>
  <el-dialog
    title="测试详情"
    :visible="visible"
    width="50%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible"
  >
    <el-collapse accordion>
      <el-collapse-item
        class="el-collapse-item"
        v-for="(item, index) in list"
        :key="item.key"
      >
        <template slot="title">
          <el-col :span="6" :offset="1"
            ><div>试题---{{ index + 1 }}</div></el-col
          >
        </template>
        <div class="topic_content">
          <!--          &lt;!&ndash; 题目 &ndash;&gt;-->
          <el-row v-if="flag == 1">
            <el-col :span="20" :offset="1">
              <div v-html="item.content"></div>
            </el-col>
          </el-row>
          <template v-if="moduleCode == 'scene'">
            <!-- 图片区 -->
            <el-row v-for="(lits, index2) in item.question" :key="lits.key">
              <el-row style="height: 83px; align-items: center" type="flex">
                <el-col :span="2" :offset="3">
                  <div>{{ index2 + 1 }}/{{ item.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div v-html="lits.question"></div>
                </el-col>
              </el-row>


              <el-row style="height: 83px; align-items: center">
                <el-col
                  v-if="lits.user_answer"
                  :span="18"
                  :offset="5"
                  style="display: flex; align-items: flex-end"
                >
                  <!-- value.is_right  是正确答案  当用户选择了正确答案时-->

                  <template v-for="(value, index3) in lits.answer">
                    <el-radio
                      style="margin: 10px; width:300px;"
                      :key="index3"
                      v-if="value.is_right == 1"
                      disabled
                      v-model="value.answer_id"
                      :label="value.answer_id"
                    >
                      <img
                        style="width: 40%"
                        :src="$http.defaults.baseIMGURL + value.answer_content"
                        class="avatar"
                      />
                      <!-- <template v-else>{{ value.answer_content }}</template> -->
                    </el-radio>

                    <!-- 当用户选择了错误答案 -->
                    <el-radio
                      style="margin: 10px; width:300px;"
                      :key="index3"
                      v-else
                      disabled
                      v-model="value.answer_id"
                      :label="value.answer_id"
                      class="text"
                    >
                     <img
                        style="width: 40%"
                        :src="$http.defaults.baseIMGURL + value.answer_content"
                        class="avatar"
                      />
                      <!-- <template v-else>{{ value.answer_content }}</template>  -->
                    </el-radio>
                  </template>
                </el-col>
                <!-- 当用户没有选择正确答案 -->
                <el-col
                  :span="18"
                  :offset="5"
                  style="display: flex; align-items: flex-end"
                  v-else
                >
                  <!-- 用户未选择 不展示任何选中状态 -->
                  <template v-for="value in lits.answer">
                    <el-radio
                      style="margin: 10px; width:300px;"
                      disabled
                      v-model="value.answer_id"
                      :key="value.answer_id"
                      :label="-1"
                    >
                      <img
                        style="width: 40%"
                        :src="$http.defaults.baseIMGURL + value.answer_content"
                        class="avatar"
                      />
                    </el-radio>
                  </template>
                </el-col>
              </el-row>
            </el-row>
          </template>
          <template v-else-if="moduleCode == 'read'">
            <el-row v-for="(lits, index2) in item.question" :key="lits.key">
              <el-row style="min-height: 43px; align-items: center" type="flex">
                <el-col :span="2" :offset="3">
                  <div>{{ index2 + 1 }}/{{ item.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div><span>{{lits.question}}:{{lits.score}}</span></div>
                </el-col>
              </el-row>
            </el-row>
          </template>
          <template v-else-if="moduleCode == 'namespeed'">
            <el-row v-for="(lits, index2) in item.question" :key="lits.key">
              <el-row style="min-height: 43px; align-items: center" type="flex">
                <el-col :span="2" :offset="3">
                  <div>{{ index2 + 1 }}/{{ item.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div><span>{{lits.question}}:{{lits.score}}</span></div>
                </el-col>
              </el-row>
            </el-row>
          </template>
          <template v-else-if="moduleCode == 'eyes'">
            <!-- 单选选择区 -->
            <el-row v-for="(lits, index2) in item.question" :key="lits.key">
              <el-row style="min-height: 43px; align-items: center" type="flex">
                <el-col :span="2" :offset="3">
                  <div>{{ index2 + 1 }}/{{ item.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div v-html="lits.question"></div>
                </el-col>
              </el-row>
              <el-row class="selection">
                <!-- 用户选择 答案正确 -->
                <el-col
                        :span="18"
                        :offset="5"
                        style="display: flex; align-items: flex-end"
                        v-if="lits.is_right"
                >
                  <el-radio
                          style="margin: 10px"
                          disabled
                          v-model="value.answer_id"
                          v-for="(value, index3) in lits.answer"
                          :label="
                      lits.user_answer == value.answer_id && value.answer_id
                    "
                  >{{ value.answer_content }}</el-radio>
                </el-col>

                <!-- 用户选择错误时候的回显 -->
                <el-col :span="18" :offset="5" v-else>
                  <el-radio
                          style="margin: 10px"
                          disabled
                          v-for="(value, index3) in lits.answer"
                          v-if="value.answer_id == lits.user_answer"
                          v-model="value.answer_id"
                          :label="value.answer_id"
                          class="text"
                  >
                    {{ value.answer_content }}
                  </el-radio>
                  <!-- user_answer 用户选择  如果没有选择默认不展示 -->
                  <el-radio
                          style="margin: 10px"
                          disabled
                          v-else
                          v-model="value.answer_id"
                          :label="
                      lits.rightselect == value.answer_id && value.answer_id
                    "
                  >
                    {{ value.answer_content }}
                  </el-radio>
                </el-col>
              </el-row>
            </el-row>
          </template>
          <template v-else>
            <!-- 单选选择区 -->
            <el-row v-for="(lits, index2) in item.question" :key="lits.key">
              <el-row style="min-height: 43px; align-items: center" type="flex">
                <el-col :span="2" :offset="3">
                  <div>{{ index2 + 1 }}/{{ item.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div v-html="lits.question"></div>
                </el-col>
              </el-row>
              <el-row class="selection">
                <!-- 用户选择 答案正确 -->
                <el-col
                  :span="18"
                  :offset="5"
                  style="display: flex; align-items: flex-end"
                  v-if="lits.is_right"
                >
                  <el-radio
                    style="margin: 10px"
                    disabled
                    v-model="value.answer_id"
                    v-for="(value, index3) in lits.answer"
                    :label="
                      lits.user_answer == value.answer_id && value.answer_id
                    "
                    >{{ value.answer_content }}</el-radio
                  >
                </el-col>

                <!-- 用户选择错误时候的回显 -->
                <el-col :span="18" :offset="5" v-else>
                  <el-radio
                    style="margin: 10px"
                    disabled
                    v-for="(value, index3) in lits.answer"
                    v-if="value.answer_id == lits.user_answer"
                    v-model="value.answer_id"
                    :label="value.answer_id"
                    class="text"
                  >
                    {{ value.answer_content }}
                  </el-radio>
                  <!-- user_answer 用户选择  如果没有选择默认不展示 -->
                  <el-radio
                    style="margin: 10px"
                    disabled
                    v-else
                    v-model="value.answer_id"
                    :label="
                      lits.user_answer == value.answer_id && value.answer_id
                    "
                  >
                    {{ value.answer_content }}
                  </el-radio>
                </el-col>
              </el-row>
            </el-row>
          </template>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div slot="footer">
      <el-button @click="resetForm">取消 </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "beforEdit",
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 父组件方法，关闭弹框
    openEdit: Function,
  },
  data() {
    return {
      // 提交状态
      loading: false,
      radio: 71,
      test_level: 0,
      form: Object.assign({}, this.data),
      // 校验
      rules: {
        test_level: [
          { required: true, message: "请选择等级", trigger: "change" },
        ],
      },
      list: [],
      flag: 1,
      levelList: [],
      // 模块类型 code = scene  是图片显示 否则：单选显示
      moduleCode: "",
    };
  },
  watch: {
    //deep: true,
    data() {
      this.$http["get"](
        "/Admin/examInfo/" + this.data.id + "/" + this.data.type
      ).then((res) => {
        if (res.data.data) {
          this.list = res.data.data.data;
          console.log(this.list);
          this.moduleCode = res.data.data.code;
          this.flag = res.data.data.flag;
        }
      });
      this.form = Object.assign({}, this.data);
    },
  },
  mounted() {},
  methods: {
    // 关闭
    resetForm(ruleForm) {
      // 调用父组件方法 隐藏弹框
      this.openEdit(null);
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit("update:visible", value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-collapse {
  border: 1px solid #f6f6f6;
  .el-collapse-item {
    .topic_content {
      background: #fafafa;
      padding-top: 34px;
      .generalization {
        height: 80px;
      }
    }
  }
}
/* 选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #2da636 !important;
}
/* 选中后小圆点的颜色 */
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #2da636 !important;
  border-color: #a7e8ac !important;
}
::v-deep .text .el-radio__input.is-checked + .el-radio__label {
  color: #e80a08 !important;
}
::v-deep .text .el-radio__input.is-checked .el-radio__inner {
  background: #e80a08 !important;
  border-color: #e80a08 !important;
}

::v-deep .success .el-radio__input.is-checked .el-radio__inner {
  background: #2da636 !important;
  border-color: #2da636 !important;
}

// 选择框样式
.selection {
  display: flex;
  align-items: center;
  min-height: 40px;
}
</style>
